body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-image: url("https://images.pexels.com/photos/5501143/pexels-photo-5501143.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-size: cover; /* Mengatur gambar latar belakang memenuhi area */
  background-attachment: fixed; /* Gambar tetap saat scroll */
  padding: 20px;
}

        
header{
  text-align: center;
  }

header li span{
  font-weight: bold;
}

  header img{
  width: 130px;
    height: 130px;
    border-radius: 50%;
    top: 200px;
  }

h1{
  text-align: center;
}

ul {
  padding: 0; 

  margin: 0;  
}

ul li {
  text-align: center;
  display: block;
}

ul.menu li a {
  text-decoration: none; /* Menghilangkan garis bawah */
  color: #000; /* Atur warna teks, jika diperlukan */
  border: 1px solid black;
  display: block;
  padding: 10px 0;
  margin: 10px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  border-radius: 5px;
}

ul.menu li a:hover {
  text-decoration: underline; /* Tampilkan garis bawah saat hover */
  color: #555; /* Ubah warna saat hover (opsional) */
}

footer ul{
  justify-content: center;
  display: flex;
  gap: 10px;
}

footer ul a{
  font-size: 20px;
}